// sentry.config.js

import * as Sentry from "@sentry/react";

const SENTRY_DSN = process.env.GATSBY_SENTRY_DSN;

Sentry.init({
  dsn: `${SENTRY_DSN}`,
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllText: false
    }),
  ],
  // Tracing settings: adjust this value for your production needs.
  tracesSampleRate: 1.0,
  // Specify for which URLs distributed tracing should be enabled.
  tracePropagationTargets: ["localhost", process.env.GATSBY_FIREBASE_FUNCTIONS_URL],
  // Session Replay: sample at 10% normally, 100% if an error occurs.
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export default Sentry;