// src/stores/makeStore.js

import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer, useEffect } from 'react';

const dispatchContext = createContext(null);
const storeContext = createContext(null);

export default function makeStore(userReducer, initialState, key) {
  const reducer = (state, action) => {
    const newState = userReducer(state, action);
    if (typeof window !== 'undefined') {
      try {
        // Only save to localStorage if newState is defined
        if (newState !== undefined) {
          localStorage.setItem(key, JSON.stringify(newState));
        } else {
          console.warn('Attempted to save undefined state to localStorage.');
        }
      } catch (error) {
        console.error('Failed to save to localStorage:', error);
      }
    }
    return newState;
  };

  const StoreProvider = ({ children }) => {
    const [store, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
      if (typeof window !== 'undefined') {
        try {
          const storedValue = localStorage.getItem(key);
          if (storedValue && storedValue !== 'undefined') {
            const storedState = JSON.parse(storedValue);
            if (storedState) {
              Object.keys(storedState).forEach((field) => {
                dispatch({ type: `set${field.charAt(0).toUpperCase() + field.slice(1)}`, value: storedState[field] });
              });
            }
          }
        } catch (error) {
          console.error('Failed to load from localStorage:', error);
        }
      }
    }, []);

    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
      </dispatchContext.Provider>
    );
  };

  StoreProvider.propTypes = {
    children: PropTypes.any,
  };

  function useDispatch() {
    return useContext(dispatchContext);
  }

  function useStore() {
    return useContext(storeContext);
  }

  return [StoreProvider, useDispatch, useStore];
}
