// src/stores/useUIStore.js

import makeStore from './makeStore';

const initialState = {
  displayCards: false,
  displayCardsManual: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setDisplayCards':
      return {
        ...state,
        displayCards: action.value,
        // Assuming 'manual' should be set here if provided
        displayCardsManual: action.manual !== undefined ? action.manual : state.displayCardsManual,
      };
    case 'setDisplayCardsManual':
      return {
        ...state,
        displayCardsManual: action.value,
      };
    default:
      console.warn('Unknown action type:', action.type);
      return state; // Return current state to avoid undefined
  }
};

const [UIProvider, useUIDispatch, useUIStore] = makeStore(reducer, initialState, 'uiStore');

export { UIProvider, useUIDispatch, useUIStore };