import '@fontsource/eb-garamond/400.css';
import '@fontsource/eb-garamond/500.css';
import '@fontsource/eb-garamond/700.css';
//fonts
import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';

import { UIProvider } from './src/stores/useUIStore';

import './src/theme/style.css';
import theme from './src/theme/theme';

const wrapRootElement = ({ element }) => {

  return (
    <ThemeProvider theme={theme}>
      <UIProvider>
        <CssBaseline />
        {element}
      </UIProvider>
    </ThemeProvider>
  );
};

export { wrapRootElement };
